<template>
	<div class="section-dash-api-keys">

		<div class="card">
			<div class="card-header">
				<h5 class="my-0">API Keys <span class="badge bg-info-light text-info">{{ apiKeys.length }}</span></h5>
			</div>
			<div class="card-body">

				<div class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>API Key</th>
								<th>Person</th>
								<th>Description</th>
								<th>Created at</th>
								<th>Hits</th>
								<th>Allowed Origins</th>
								<th>Allowed IPs</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="apiKey in apiKeys" :key="`p-${apiKey.id}`">
								<td><code>{{ apiKey.key }}</code></td>
								<td>
									<span v-if="people[apiKey.person_id]">
										{{ people[apiKey.person_id].name }}
									</span>
									<span v-else>[{{ apiKey.person_id }}]</span>
								</td>
								<td>{{ apiKey.description }}</td>
								<td>{{ apiKey.created_at | dateLocal }}</td>
								<td>{{ apiKey.hits.toLocaleString() }}</td>
								<td>
									<p v-for="origin in apiKey.allowed_origins" :key="`a-${apiKey.id}-o-${origin}`" class="mb-0">
										<span class="badge bg-info-lighter text-info">{{ truncateText(origin, 30) }}</span>
									</p>
								</td>
								<td>
									<p v-for="ip in apiKey.allowed_ips" :key="`a-${apiKey.id}-i-${ip}`" class="mb-0">
										<span class="badge bg-info-lighter text-info">{{ truncateText(ip, 30) }}</span>
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'

import domainsApi from "@/api.js"

export default {
	name: 'DashboardApiKeys',
	data() {
		return {
			state: 'loading',
			apiKeys: [],
		}
	},
	computed: {
		...mapState(['people']),
	},
	created() {
		this.loadApiKeys()
	},
	methods: {
		loadApiKeys() {

			domainsApi.get('/people/api-keys').then(({ data }) => {
				this.$store.dispatch('loadPeople', data.map(l => l.person_id))
				this.apiKeys.push(...data)

				this.state = 'idle'
			}, Vue.toasted.error)

		},
		truncateText(text, length) {

			if (text.length > length) {
				text = text.slice(0, length) + '..'
			}

			return text
		}
	}
}
</script>

<template>
	<div class="section-dash-overview">

		<div class="row mb-4">
			<div class="col">
				<a href="https://dash.cloudflare.com/ecd0a15fbfdda697b3bdab0056a942af/web-analytics/overview?siteTag~in=593d966599e94348ad6ccf41a80b9308" target="_blank" class="card text-center">
					<div class="card-body">
						<p class="my-0">📊 Web app analytics</p>
					</div>
				</a>
			</div>

			<div class="col">
				<a href="https://dashboard.stripe.com/acct_1JWJc7JHKoI7hSyX" target="_blank" class="card text-center">
					<div class="card-body">
						<p class="my-0">💰 Stripe Dashboard</p>
					</div>
				</a>
			</div>

			<div class="col">
				<a href="https://app.openreplay.com/3462/sessions" target="_blank" class="card text-center">
					<div class="card-body">
						<p class="my-0">🕵️ Errors & sessions reporting</p>
					</div>
				</a>
			</div>
		</div>

		<div class="row">
			<div class="col-lg-7">

				<div class="card mb-3">
					<div class="card-header">
						<h5 class="my-0">They just signed up</h5>
					</div>
					<div class="card-body">
						<div class="card-table">
							<table class="table table-hover table-sm">
								<thead>
									<tr>
										<th>Name</th>
										<th>Email</th>
										<th>C</th>
										<th>When</th>
										<th>Dmns</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="person in people" :key="`p-${person.id}`">
										<td><router-link :to="`/people/${person.id}`">{{ person.name }}</router-link></td>
										<td>{{ person.email }}</td>
										<td>
											<a :href="`https://ipinfo.io/${person.from_ip}`" target="_blank">
												<span v-if="person.country_code">{{ flag(person.country_code) }} {{ person.country_code }}</span>
												<span v-else>IP</span>
											</a>
										</td>
										<td>{{ person.created_at | dateToNow }}</td>
										<td>{{ person.domains }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-5">
				<div class="card mb-3">
					<div class="card-header">
						<h5 class="my-0">Popular keywords past week</h5>
					</div>
					<div class="card-body">
						<div class="card-table">
							<table class="table table-hover table-sm">
								<thead>
									<tr>
										<th>Keyword</th>
										<th>Weekly</th>
										<th>All time</th>
										<th>First seen</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="keyword in keywords" :key="`k-${keyword.id}`">
										<td><router-link :to="`/domains?q=${keyword.keyword}`">{{ keyword.keyword }}</router-link></td>
										<td>{{ keyword.views_weekly }}</td>
										<td>{{ keyword.views }}</td>
										<td>{{ new Date(keyword.created_at).toLocaleDateString() }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<div class="card mb-3">
					<div class="card-header">
						<h5 class="my-0">Latest created lists</h5>
					</div>
					<div class="card-body">
						<div class="card-table">
							<table class="table table-hover table-sm">
								<thead>
									<tr>
										<th>List</th>
										<th>By</th>
										<th>When</th>
										<th>Dmns</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="list in lists" :key="`l-${list.id}`">
										<td><router-link :to="`/collections/${list.slug}`">{{ list.name }}</router-link></td>
										<td>{{ list.person_id }}</td>
										<td>{{ list.created_at | dateToNow }}</td>
										<td>{{ list.domains_count }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		

	</div>
</template>

<script>
import { flag } from 'country-emoji'
//import domainsApi from "@/api.js"

export default {
	name: 'DashboardOverview',
	props: {
		people: Array,
		lists: Array,
		keywords: Array,
	},
	data() {
		return {
			
		}
	},
	created() {
		//this.loadKeywords()
	},
	methods: {
		flag
	}
}
</script>

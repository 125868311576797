<template>
	<div class="section-dash-people">

		<div class="card">
			<div class="card-body">

				<div class="row mb-2">
					<div class="col-2">
						<h5>Plans</h5>
					</div>
					<div v-if="stats" class="col">
						<span v-for="plan in stats.plans" :key="`plan-${plan.plan}`" class="badge bg-success-lighter text-dark me-2 my-1"><strong>{{ plan.total }}</strong> {{ plan.plan }}</span>
					</div>
				</div>

				<div class="row mb-2">
					<div class="col-2">
						<h5>Countries</h5>
					</div>
					<div v-if="stats" class="col">
						<span v-for="country in stats.countries.slice(0, 12)" :key="`plan-${country.country_code}`" class="badge bg-success-lighter text-dark me-2 my-1"><strong>{{ country.total }}</strong> {{ flag(country.country_code) }} {{ country.country_code }}</span>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-2" @click="states.allEmailDomains = !states.allEmailDomains">
						<h5>Emails</h5>
					</div>
					<div v-if="stats" class="col">
						<router-link v-for="emailDomain in stats.emailDomains.slice(0, states.allEmailDomains ? 9999 : 10)" :key="`emd-${emailDomain.email_domain}`" :to="`/${emailDomain.email_domain}`" class="badge bg-info-lighter text-dark me-2 my-1"><strong>{{ emailDomain.total }}</strong> {{ emailDomain.email_domain }}</router-link>
					</div>
				</div>

				<pre v-if="0">{{ stats }}</pre>

				<div class="card-table mb-3">
					<table class="table table-hover">
						<thead>
							<tr>
								<th class="hover cursor-pointer" @click="orderBy('name', 'asc')">
									Name <small v-if="order.orderBy === 'name'">{{ order.order == 'asc' ? '▲' : '▼' }}</small>
								</th>
								<th class="hover cursor-pointer" @click="orderBy('created_at', 'desc')">
									Signed up <small v-if="order.orderBy === 'created_at'">{{ order.order == 'asc' ? '▲' : '▼' }}</small>
								</th>
								<th class="hover cursor-pointer" @click="orderBy('last_login_at', 'desc')">
									Last login <small v-if="order.orderBy === 'last_login_at'">{{ order.order == 'asc' ? '▲' : '▼' }}</small>
								</th>
								<th class="hover cursor-pointer" @click="orderBy('country_code', 'asc')">
									Country <small v-if="order.orderBy === 'country_code'">{{ order.order == 'asc' ? '▲' : '▼' }}</small>
								</th>
								<th>Meta</th>
								<th class="cursor-pointer" @click="orderBy('domains', 'desc')">
									Plan/Dmns <small v-if="order.orderBy === 'domains'">{{ order.order == 'asc' ? '▲' : '▼' }}</small>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="person in people" :key="`p-${person.id}`">
								<td>
									<p class="mb-1 lead">{{ person.name }}</p>
									<p class="mb-0">
										<a :href="`https://account.postmarkapp.com/servers/5167243/streams/outbound/addresses/${person.email}`" target="_blank">{{ person.email }}</a>
										<span v-if="'newEmailToken' in person.meta" class="badge bg-danger-light text-danger ms-1">Not confirmed</span>
									</p>
								</td>
								<td>{{ new Date(person.created_at).toLocaleDateString() }}</td>
								<td>{{ new Date(person.last_login_at).toLocaleDateString() }}</td>
								<td>
									<a :href="`https://ipinfo.io/${person.from_ip}`" target="_blank">{{ flag(person.country_code) }} {{ person.country_code || 'IP' }}</a>
								</td>
								<td>
									<p v-if="person.ua" class="mb-1">{{ person.ua.device && person.ua.device.type === 'mobile' ? '📱' : '🖥' }} {{ [person.ua.browser.name, person.ua.os.name].filter(Boolean).join(' / ') }}</p>
									<pre v-if="person.showMeta">{{ person.meta }}</pre>
									<span v-else>
										<p v-for="label in Object.keys(person.meta)" :key="`p-${person.id}-meta-${label}`" class="mb-0"><span class="badge bg-light text-gray">{{ label }}</span></p>
									</span>
								</td>
								<td>
									<p class="mb-1">{{ person.plan }} <a v-if="person.stripe_subscription_id" :href="`https://dashboard.stripe.com/subscriptions/${person.stripe_subscription_id}`" class="badge bg-info-light text-info">Sub</a></p>
									<p class="mb-0"><button class="btn btn-sm btn-outline-info" @click="loadFollowedDomains(person)" data-bs-toggle="modal" data-bs-target="#person-domains">{{ person.domains }}/{{ person.domains_limit }}</button></p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<p>[pagination here]</p>
			</div>
		</div>

		<div class="modal fade" id="person-domains" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Followed domains</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="card-table">
							<table class="table table-sm table-hover">
								<thead>
									<tr>
										<th>Domain</th>
										<th>Availability</th>
										<th>Activity</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="domain in followedDomains" :key="`f-d-${domain.id}`">
										<td><router-link :to="`/${domain.domain}`">{{ domain.domain }}</router-link></td>
										<td>{{ domain.availability }}</td>
										<td>{{ domain.activity_count }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import domainsApi from "@/api.js"
import Vue from 'vue'
import { flag } from 'country-emoji'

export default {
	name: 'DashboardPeople',
	data() {
		return {
			states: {
				allEmailDomains: false,
			},
			state: 'loading',
			pag: {
				page: 1,
				perPage: 50,
				total: 0,
			},
			order: {
				order: 'desc',
				orderBy: 'created_at',
			},
			people: [],
			followedDomains: [],
			stats: null,
		}
	},
	computed: {
		
	},
	created() {
		this.loadPeople()
		this.loadStats()
	},
	methods: {
		flag,

		loadPeople() {

			domainsApi.get('/people', { params: { ...this.order, ...this.pag } }).then(({ data }) => {
				data = data.map(person => {
					person.showMeta = false

					return person
				})

				this.people.push(...data)
				this.state = 'idle'
			}, Vue.toasted.error)

		},
		orderBy(field, defaultOrder) {
			this.order.order = this.order.orderBy == field ? (this.order.order === 'asc' ? 'desc' : 'asc') : defaultOrder
			this.order.orderBy = field
			this.people = []

			this.loadPeople()
		},
		loadFollowedDomains(person) {
			this.followedDomains = []

			domainsApi.get(`/people/${person.id}/domains`).then(({ data }) => {
				this.followedDomains.push(...data)
			}, Vue.toasted.error)
		},

		loadStats() {
			domainsApi.get('/people/stats').then(({ data }) => {
				this.stats = data
			}, Vue.toasted.error)
		},
	}
}
</script>

<template>
	<div class="section-dash-api-keys">

		<div class="row mb-4">
			<div class="col">
				<div class="card text-center">
					<div class="card-body py-4">
						<h4 class="my-0">{{ servers.length }} WHOIS servers</h4>
					</div>
				</div>
			</div>

			<div class="col">
				<div class="card ">
					<div class="card-body py-2">
						<p class="lead mb-2"><strong>{{ checksPerHour }}</strong> WHOIS checks / hour</p>

						<p class="lead my-1"><strong>500</strong> domains monitored</p>
						<p class="lead my-1"><strong>{{ checksPerHour - 500 }}</strong> other domains checked</p>
					</div>
				</div>
			</div>

			<div class="col">
				<div class="card">
					<div class="card-body">
						-
					</div>
				</div>
			</div>
		</div>

		<div class="card">
			<div class="card-header">
				<h5 class="my-0">WHOIS Monitoring</h5>
			</div>
			<div class="card-body">

				<div class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>Server</th>
								<th>Checks</th>
								<th>First ping</th>
								<th>Last ping</th>
								<th>Errors</th>
								<th>Error rate</th>
								<th>Averate run time</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="server in servers" :key="`p-${server.host}`">
								<td><code>{{ server.host }}</code></td>
								<td>{{ server.checks }}</td>
								<td>{{ server.first_seen | dateToNow }}</td>
								<td>{{ server.last_seen | dateToNow }}</td>
								<td>{{ server.error_rates.filter(Boolean).length }}</td>
								<td>{{ server.error_rates | average }}%</td>
								<td>{{ server.timings | average(1) }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { sum } from 'lodash'
import { mapState } from 'vuex'
import Vue from 'vue'

import domainsApi from "@/api.js"

export default {
	name: 'DashboardApiKeys',
	data() {
		return {
			state: 'loading',
			servers: [],
		}
	},
	computed: {
		...mapState(['people']),
		checksPerHour() {
			return sum(this.servers.map(s => s.domains.length)) * 60
		}
	},
	created() {
		this.loadServers()
	},
	methods: {
		loadServers() {
			domainsApi.get('/stats/monitoring-servers').then(({ data }) => {
				this.servers.push(...data)

				this.state = 'idle'
			}, Vue.toasted.error)
		},
	}
}
</script>

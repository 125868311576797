<template>
	<div class="page-dash">

		<section class="page-head no-bg">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-8 my-5 text-center">
						<h1 class="display-5 my-0">☢️ Dashboard</h1>
					</div>
				</div>

				<ul class="nav nav-tabs mb-2">
					<li class="nav-item">
						<router-link to="/dash" class="nav-link" :class="{active: $route.name === 'Dashboard'}">Overview</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/dash/people" class="nav-link" :class="{active: $route.name === 'DashboardPeople'}">👥 People <span class="badge bg-info-lighter text-dark">{{ totals.people.toLocaleString() }}</span></router-link>
					</li>
					<li class="nav-item">
						<router-link to="/dash/lists" class="nav-link" :class="{active: $route.name === 'DashboardLists'}">🗃 Lists <span class="badge bg-info-lighter text-dark">{{ totals.lists.toLocaleString() }}</span></router-link>
					</li>
					<li class="nav-item">
						<router-link to="/dash/keywords" class="nav-link" :class="{active: $route.name === 'DashboardKeywords'}">🔎 Keywords <span class="badge bg-info-lighter text-dark">{{ totals.keywords.toLocaleString() }}</span></router-link>
					</li>
					<li class="nav-item">
						<router-link to="/dash/sales" class="nav-link" :class="{active: $route.name === 'DashboardSales'}">💸 Sales <span class="badge bg-info-lighter text-dark">{{ totals.sales.toLocaleString() }}</span></router-link>
					</li>
					<li class="nav-item">
						<router-link to="/dash/api-keys" class="nav-link" :class="{active: $route.name === 'DashboardApiKeys'}">⚙️ API Keys</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/dash/servers" class="nav-link" :class="{active: $route.name === 'DashboardServers'}">🗄 Servers</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/dash/emails" class="nav-link" :class="{active: $route.name === 'DashboardEmails'}">📧 Emails</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/stats" class="nav-link">Stats</router-link>
					</li>
				</ul>
			</div>
		</section>

		<div class="container-fluid pb-5">

			<router-view :people="people" :lists="lists" :keywords="keywords"></router-view>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'

import domainsApi from "@/api.js"

export default {
	name: 'Dash',
	data() {
		return {
			totals: {
				lists: 0,
				sales: 0,
				people: 0,
				domains: 0,
				keywords: 0,
			},
			lists: [],
			sales: [],
			people: [],
			domains: [],
			keywords: [],
		}
	},
	computed: {
		...mapGetters([ 'isStaff' ]),
	},
	created() {
		if (this.isStaff) {
			this.loadStats()
		} else {
			this.$router.push('/account')
		}
	},
	methods: {
		loadStats() {

			// sales data
			domainsApi.get('/activities', { params: { type: 'sale', perPage: 15 } }).then(({ data, headers }) => {
				this.totals.sales = parseInt(headers['x-total'], 10)
				this.sales.push(...data)
			}, Vue.toasted.error)

			// people data
			domainsApi.get('/people', { params: { active: 1, perPage: 10 } }).then(({ data, headers }) => {
				this.totals.people = parseInt(headers['x-total'], 10)
				this.people.push(...data)
			}, Vue.toasted.error)

			// keywords data
			domainsApi.get('/keywords?length=2&perPage=10&orderBy=views_weekly').then(({ data, headers }) => {
				this.totals.keywords = parseInt(headers['x-total'], 10)
				this.keywords.push(...data)
			})

			// lists data
			domainsApi.get('/lists', { params: { perPage: 7, orderBy: 'id', order: 'desc' } }).then(({ data, headers }) => {
				this.totals.lists = parseInt(headers['x-total'], 10)
				this.lists.push(...data)
			}, Vue.toasted.error)
		},
	}
}
</script>

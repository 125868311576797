<template>
	<div class="section-dash-lists">

		<div class="card">
			<div class="card-body">
				<div class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th class="hover cursor-pointer" @click="orderBy('name', 'asc')">
									Name <small v-if="order.orderBy === 'name'">{{ order.order == 'asc' ? '▲' : '▼' }}</small>
								</th>
								<th class="hover cursor-pointer" @click="orderBy('domains_count', 'desc')">
									Domains <small v-if="order.orderBy === 'domains_count'">{{ order.order == 'asc' ? '▲' : '▼' }}</small>
								</th>
								<th class="hover cursor-pointer" @click="orderBy('person_id', 'asc')">
									Person <small v-if="order.orderBy === 'person_id'">{{ order.order == 'asc' ? '▲' : '▼' }}</small>
								</th>
								<th class="hover cursor-pointer" @click="orderBy('public', 'desc')">
									Public <small v-if="order.orderBy === 'public'">{{ order.order == 'asc' ? '▲' : '▼' }}</small>
								</th>
								<th class="hover cursor-pointer" @click="orderBy('featured', 'desc')">
									Featured <small v-if="order.orderBy === 'featured'">{{ order.order == 'asc' ? '▲' : '▼' }}</small>
								</th>
								<th class="hover cursor-pointer" @click="orderBy('created_at', 'desc')">
									Created at <small v-if="order.orderBy === 'created_at'">{{ order.order == 'asc' ? '▲' : '▼' }}</small>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="list in lists" :key="`l-${list.id}`">
								<td>
									<router-link :to="`/collections/${list.slug}`">{{ list.name }}</router-link>
								</td>
								<td>
									<button class="btn btn-sm btn-outline-dark" @click="loadListDomains(list)" data-bs-toggle="modal" data-bs-target="#list-domains">{{ list.domains_count }}</button>
								</td>
								<td>
									<span v-if="people[list.person_id]">
										<img class="rounded-circle me-1" :src="`https://secure.gravatar.com/avatar/${md5(people[list.person_id].email)}?s=50&d=mm`" alt="Photo" width="28" height="28" />
										{{ people[list.person_id].name }}
									</span>
									<span v-else>{{ list.person_id }}</span>
								</td>
								<td>
									<span v-if="list.status === 'public'" class="text-success">Public</span>
									<span v-else-if="list.status === 'unlisted'" class="text-info">Unlisted</span>
									<span v-else-if="list.status === 'private'" class="text-warning">Private</span>
									<code v-else>{{ list.status }}</code>
								</td>
								<td>{{ list.featured ? '✅' : '' }}</td>
								<td>{{ list.created_at | dateLocal }} <small class="text-muted">{{ list.created_at | dateToNow }}</small></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div class="modal fade" id="list-domains" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Domains in list</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<table class="table">
							<thead>
								<tr>
									<th>Domain</th>
									<th>Availability</th>
									<th>Activity</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="domain in listDomains" :key="`f-d-${domain.id}`">
									<td><router-link :to="`/${domain.domain}`">{{ domain.domain }}</router-link></td>
									<td>{{ domain.availability }}</td>
									<td>{{ domain.activity_count }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import domainsApi from "@/api.js"
import Vue from 'vue'
import { mapState } from 'vuex'
import md5 from 'md5'

export default {
	name: 'DashboardLists',
	data() {
		return {
			state: 'loading',
			order: {
				order: 'desc',
				orderBy: 'created_at',
			},
			lists: [],
			listDomains: [],
		}
	},
	computed: {
		...mapState(['people']),
	},
	created() {
		this.loadLists()
	},
	methods: {
		md5,

		loadLists() {
			domainsApi.get('/lists', { params: { ...this.order } }).then(({ data }) => {
				this.$store.dispatch('loadPeople', data.map(l => l.person_id))

				this.lists.push(...data)
				this.state = 'idle'
			}, Vue.toasted.error)

		},
		orderBy(field, defaultOrder) {
			this.order.order = this.order.orderBy == field ? (this.order.order === 'asc' ? 'desc' : 'asc') : defaultOrder
			this.order.orderBy = field
			this.lists = []

			this.loadLists()
		},

		loadListDomains(list) {
			this.listDomains = []

			domainsApi.get(`/domains?list=${list.id}`).then(({ data }) => {
				this.listDomains.push(...data)
			}, Vue.toasted.error)
		},
	}
}
</script>

<template>
	<div class="section-dash-sales">

		<div class="card">
			<div class="card-header">
				<button class="btn btn-sm btn-outline-primary float-end ms-2" @click="importSalesYesterday" :disabled="states.import === 'importing'">Import yesterday's sales</button>
				<button class="btn btn-sm btn-outline-primary float-end ms-2" @click="importSalesForDate" :disabled="states.import === 'importing'">Import sales for date</button>

				<h5 class="my-0">Sales</h5>
			</div>
			<div class="card-body">
				<div class="row mb-2">
					<div class="col form-group">
						<label class="form-label mb-1">From date</label>
						<input type="date" class="form-control form-control-sm" v-model="filters.date_start">
					</div>

					<div class="col form-group">
						<label class="form-label mb-1">To date</label>
						<input type="date" class="form-control form-control-sm" v-model="filters.date_end">
					</div>

					<div class="col form-group">
						<label class="form-label mb-1">TLD</label>
						<select class="form-select form-select-sm" v-model="filters.tld">
							<option value="">All TLDs</option>
							<option value="com">.com</option>
							<option value="net">.net</option>
							<option value="org">.org</option>
							<option value="co">.co</option>
							<option value="app">.app</option>
							<option value="xyz">.xyz</option>
						</select>
					</div>
				</div>

				<div class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th class="hover cursor-pointer">Domain</th>
								<th class="hover cursor-pointer" @click="orderBy('price', 'desc')">
									Price <span v-if="order.orderBy === 'price'">{{ order.order === 'asc' ? '▲' : '▼' }}</span>
								</th>
								<th class="hover cursor-pointer" @click="orderBy('venue', 'desc')">Venue</th>
								<th class="hover cursor-pointer" @click="orderBy('created_at', 'desc')">
									Date <span v-if="order.orderBy === 'created_at'">{{ order.order === 'asc' ? '▲' : '▼' }}</span>
								</th>
								<th>NameServers</th>
								<th>Registrant</th>
								<th>Registrar</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="sale in sales" :key="`l-${sale.id}`">
								<td>
									<router-link :to="`/${sale.domain.domain}`">{{ sale.domain.domain }}</router-link>
									<span v-if="sale.domain.monitoring">📸</span>
								</td>
								<td>
									{{ sale.data.price | currency }}
								</td>
								<td>
									{{ sale.data.venue }}
								</td>
								<td>
									<p class="mb-1">{{ sale.created_at | dateLocal }}</p>
									<small class="text-muted">{{ sale.created_at | dateToNow }}</small>
								</td>
								<td>
									<span v-for="ns in sale.domain.ns" :key="`${sale.domain.domain}-${ns}`" class="badge bg-light text-dark m-1">{{ ns }}</span>
								</td>
								<td>
									<p class="mb-1">👤 {{ [sale.domain.registrant.name, sale.domain.registrant.organization].filter(Boolean).map(t => truncateString(t, 20)).join(', ') }}</p>
									<p class="mb-0">📍 {{ [sale.domain.registrant.city, sale.domain.registrant.stateOrProvince, sale.domain.registrant.country, ].filter(Boolean).join(', ') }}</p>
								</td>
								<td>
									<a v-if="sale.domain.registrar.url" :href="sale.domain.registrar.url" target="_blank" rel="noopener">{{ sale.domain.registrar.name }}</a>
									<span v-else-if="sale.domain.registrar.name">{{ sale.domain.registrar.name }}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import Vue from 'vue'

import domainsApi from "@/api.js"
import { truncateString } from "@/utils.js"

export default {
	name: 'DashboardSales',
	data() {
		return {
			states: {
				sales: 'loading',
				import: 'idle',
			},
			filters: {
				date_start: this.$route.query.date_start || null,
				date_end: this.$route.query.date_end || null,
				tld: this.$route.query.tld || '',
			},
			pag: {
				page: 1,
				perPage: 100,
				total: 0,
			},
			order: {
				order: 'desc',
				orderBy: this.$route.query.order_by || 'created_at',
			},
			sales: [],
		}
	},
	created() {
		this.loadSales()
	},
	methods: {
		truncateString,

		loadSales() {
			this.states.sales = 'loading'

			const params = {
				type: 'sale',
				...this.filters,
				page: this.pag.page,
				perPage: this.pag.perPage,
				...this.order,
			}

			domainsApi.get('/activities', { params }).then(({ data, headers }) => {
				this.pag.total = parseInt(headers['x-total'], 10)

				data = data.map(sale => {
					sale.domain.ns = JSON.parse(sale.domain.ns)
					sale.domain.registrar = JSON.parse(sale.domain.registrar)
					sale.domain.registrant = JSON.parse(sale.domain.registrant)

					return sale
				})

				this.sales.push(...data)
				this.states.sales = 'loaded'
			}, Vue.toasted.error)

		},
		orderBy(field, defaultOrder) {
			this.order.order = this.order.orderBy == field ? (this.order.order === 'asc' ? 'desc' : 'asc') : defaultOrder
			this.order.orderBy = field
			this.sales = []

			this.loadSales()
		},

		importSalesForDate() {
			const date = prompt('Date (yyyy-mm-dd)')

			if (date) {
				this.importSales({download: 1, date})
			}
		},

		importSalesYesterday() {
			this.importSales({download: 1})
		},

		importSales(params) {
			this.states.import = 'importing'

			domainsApi.get('/domains/import-sales', { params }).then(({ data }) => {
				this.order.orderBy = 'price'
				this.filters.date_start = data.date

				this.states.import = 'idle'
				Vue.toasted.success(data.text || data)
			}, error => {
				this.states.import = 'error'
				Vue.toasted.error(`Error importing sales (${error})`)
			})
		}

	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.sales = []
				this.loadSales()
			}
		}
	},
}
</script>

<template>
	<div class="section-dash-api-keys">

		<div class="card">
			<div class="card-header">
				<h5 class="my-0">Emails</h5>
			</div>
			<div class="card-body">

				<div class="mb-3">
					<span class="badge bg-light text-dark mb-1 me-1">All domains</span>
					<span v-for="unread in unreads" :key="unread.domain" class="badge mb-1 me-1" :class="unread.domain === domain ? 'bg-primary-lighter text-primary' : 'bg-light text-dark'" @click="loadEmailsForDomain(unread.domain)">{{ unread.domain }} <strong class="text-primary">{{ unread.total }}</strong></span>
				</div>

				<div class="bg-light p-3 mb-3">
					<h5 class="mb-3">Domain Info <code>{{ domain }}</code></h5>

					<div class="row">
						<div class="col">
							<h6>From addresses</h6>

							<pre>{{ domainInfo.from }}</pre>
						</div>

						<div class="col">
							<h6>To addresses</h6>

							<pre>{{ domainInfo.to }}</pre>
						</div>

						<div class="col">
							<h6>Envelope</h6>

							<pre>{{ domainInfo.envelope }}</pre>
						</div>
					</div>
				</div>

				<div class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th v-if="!domain">Domain</th>
								<th>From</th>
								<th>To</th>
								<th>Subject</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="email in emails" :key="email.id" :class="{'bg-primary-lighter': !email.seen}" @click="showEmail(email)" data-bs-toggle="modal" data-bs-target="#modal-incoming-email">
								<td v-if="!domain"><code>{{ email.domain }}</code></td>
								<td>
									<span v-for="from in email.from" :key="`emm-${email.id}-fr-${from.address}`" class="badge bg-light text-dark">
										{{ [from.name, from.address].filter(Boolean).join(', ') }}<span v-if="from.address == email.envelope.mailFrom.address"> ✅</span>
									</span>
								</td>
								<td>
									<span v-for="to in email.to" :key="`emm-${email.id}-to-${to.address}`" class="badge bg-info-lighter text-dark">
										{{ [to.name, to.address].filter(Boolean).join(', ') }}<span v-if="email.envelope.rcptTo.find(tt => tt.address == to.address)"> ✅</span>
									</span>
								</td>
								<td>
									<span :class="{'fw-bold': !email.seen}">{{ email.subject }}</span>
									<span v-if="email.attachments.length" class="ms-1" :title="`${email.attachments.length} attachments`">🔗</span>
								</td>
								<td>{{ email.date | dateLocal }}</td>
							</tr>
							<tr v-if="state === 'loading'">
								<td class="text-center py-5" colspan="5">loading..</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-incoming-email" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content border-0 shadow-sm">
					<div class="modal-header">
						<h5 class="modal-title my-0">{{ emailIncoming ? emailIncoming.subject : '...' }}</h5>
						<button type="button" class="btn-close text-muted" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>

					<div v-if="emailIncoming" class="modal-body">
						<p class="mb-1">ID: <strong>{{ emailIncoming.id }}</strong>, Message ID: <strong>{{ emailIncoming.message_id }}</strong></p>
						<p class="mb-1">Date: <strong>{{ new Date(emailIncoming.date).toLocaleString() }}</strong></p>
						<p class="mb-1">Envelope: <strong>{{ emailIncoming.envelope }}</strong></p>
						<p class="mb-1">From: <span v-for="from in emailIncoming.from" :key="`emm-${emailIncoming.id}-fr-${from.address}`" class="badge bg-light text-dark">{{ [from.name, from.address].filter(Boolean).join(', ') }}<span v-if="from.address == emailIncoming.envelope.mailFrom.address"> ✅</span></span></p>
						<p class="mb-3">To: <span v-for="to in emailIncoming.to" :key="`emm-${emailIncoming.id}-to-${to.address}`" class="badge bg-info-lighter text-dark">{{ [to.name, to.address].filter(Boolean).join(', ') }}<span v-if="emailIncoming.envelope.rcptTo.find(tt => tt.address == to.address)"> ✅</span></span></p>

						<div v-if="emailIncoming.text" v-html="emailIncoming.text.replace(/\n/g, '<br>')" class="border rounded p-2 mb-3"></div>
						<iframe v-if="0 && emailIncoming.html" :src="`data:text/html;charset=utf-8,${emailIncoming.html}`" class="border rounded p-2"></iframe>

						<div class="row row-cols-2">
							<div v-for="att in emailIncoming.attachments" :key="`em2-${att.cid || att.size}`" class="col mb-2">
								<div class="bg-light rounded p-1">
									<p class="mb-2">{{ att.filename || '(-)' }} <span class="badge bg-info-lighter text-info me-1">{{ att.contentType }}</span> <span class="badge bg-info-lighter text-info">{{ Math.round(att.size / 1024) }}kb</span></p>

									<img v-if="att.contentType.startsWith('image/')" :src="`data:${att.contentType};base64,${bufferToBase64(att.content.data)}`" class="img-fluid" alt="att" />
									<div v-else>
										[preview here]
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import Vue from 'vue'

import domainsApi from "@/api.js"

export default {
	name: 'DashboardEmails',
	data() {
		return {
			state: 'loading',
			domain: null,
			emails: [],
			unreads: null,
			emailIncoming: null,
		}
	},
	created() {
		this.loadEmails()

		domainsApi('/stats/incoming-emails-unread').then(({ data }) => {
			this.unreads = data
		})
	},
	computed: {
		domainInfo() {
			const info = {
				to: {},
				from: {},
				envelope: {
					from: {},
					to: {},
				}
			}

			if (this.domain) {
				this.emails.forEach(email => {
					email.to.forEach(to => {
						if (to.address) {
							const address = to.address.toLowerCase()
							info.to[address] ||= 0
							info.to[address]++
						}
					})

					email.from.forEach(from => {
						const address = from.address.toLowerCase()
						info.from[address] ||= 0
						info.from[address]++
					})

					info.envelope.from[email.envelope.mailFrom.address] ||= 0
					info.envelope.from[email.envelope.mailFrom.address]++

					email.envelope.rcptTo.forEach(to => {
						info.envelope.to[to.address] ||= 0
						info.envelope.to[to.address]++
					})
				})
			}

			return info
		},
	},
	methods: {
		loadEmailsForDomain(domain) {
			if (domain !== this.domain) {
				this.domain = domain
				this.emails = []
				this.loadEmails()
			}
		},
		loadEmails() {
			this.state = 'loading'
			const params = {
				domain: this.domain,
			}

			domainsApi.get('/stats/incoming-emails', { params })
				.then(({ data }) => {
					this.emails.push(...data)
				}, Vue.toasted.error)
				.finally(() => {
					this.state = 'idle'
				})
		},
		showEmail(email) {
			domainsApi.post(`/stats/incoming-emails/${email.id}`, {
				seen: 1,
			}).then(() => {
				email.seen = true
			})
			this.emailIncoming = email
		},
		encode(input) {
			var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
			var output = "";
			var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
			var i = 0;

			while (i < input.length) {
				chr1 = input[i++];
				chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index 
				chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

				enc1 = chr1 >> 2;
				enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
				enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
				enc4 = chr3 & 63;

				if (isNaN(chr2)) {
					enc3 = enc4 = 64;
				} else if (isNaN(chr3)) {
					enc4 = 64;
				}
				output += keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4);
			}
			return output;
		},
		bufferToBase64(buffer) {
			const bytes = new Uint8Array(buffer);
			return this.encode(bytes)
		},
	}
}
</script>

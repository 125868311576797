<template>
	<div class="section-dash-keywords">

		<div class="card">
			<div class="card-body">
				<div class="row align-items-center mb-3">
					<div class="col form-group">
						<strong>{{ pag.total.toLocaleString() }}</strong> keywords found
					</div>

					<div class="col form-group">
						<input type="search" class="form-control form-control-sm" v-model="filters.q" placeholder="Search..">
					</div>

					<div class="col form-group">
						<select class="form-select form-select-sm" v-model="filters.length">
							<option :value="null">All lengths</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
							<option value="6">6</option>
						</select>
					</div>

					<div class="col form-group">
						<div class="form-check">
							<input class="form-check-input" type="checkbox" v-model="filters.person" id="keyword-person">
							<label class="form-check-label" for="keyword-person">Created by known person</label>
						</div>
					</div>
				</div>

				<div class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th class="hover cursor-pointer" @click="orderBy('keyword', 'asc')">
									Keyword <span v-if="order.orderBy === 'keyword'">{{ order.order === 'asc' ? '▲' : '▼' }}</span>
								</th>
								<th class="hover cursor-pointer" @click="orderBy('length', 'asc')">
									Length <span v-if="order.orderBy === 'length'">{{ order.order === 'asc' ? '▲' : '▼' }}</span>
								</th>
								<th>Is</th>
								<th class="hover cursor-pointer" @click="orderBy('views', 'desc')">
									Hits <span v-if="order.orderBy === 'views'">{{ order.order === 'asc' ? '▲' : '▼' }}</span>
								</th>
								<th>First search by</th>
								<th class="hover cursor-pointer" @click="orderBy('created_at', 'desc')">
									Created on <span v-if="order.orderBy === 'created_at'">{{ order.order === 'asc' ? '▲' : '▼' }}</span>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="keyword in keywords" :key="keyword.id">
								<td>
									<router-link :to="`/domains?q=${keyword.keyword}`" v-html="highlightQuery(keyword.keyword)"></router-link>
								</td>
								<td>
									{{ keyword.length }}
								</td>
								<td>
									<span v-if="keyword.is_word" class="badge bg-info-lighter text-info me-1">word</span>
									<span v-if="keyword.is_brand" class="badge bg-warning-lighter text-warning me-1">brand</span>
									<span v-if="keyword.is_name" class="badge bg-success-lighter text-success me-1">name</span>
									<span v-if="keyword.is_location" class="badge bg-info-lighter text-info me-1">loc</span>
								</td>
								<td>
									{{ keyword.views }} / {{ keyword.views_weekly }}
								</td>
								<td>
									<span v-if="people[keyword.person_id]">
										<img class="rounded-circle me-1" :src="`https://secure.gravatar.com/avatar/${md5(people[keyword.person_id].email)}?s=50&d=mm`" alt="Photo" width="24" height="24" />
										{{ people[keyword.person_id].name }}
									</span>
									<span v-else>{{ keyword.person_id }}</span>
								</td>
								<td>{{ keyword.created_at | dateLocal }} <small class="text-muted">{{ keyword.created_at | dateToNow }}</small></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import md5 from 'md5'

import domainsApi from "@/api.js"

export default {
	name: 'DashboardKeywords',
	data() {
		return {
			states: {
				keywords: 'loading',
				import: 'idle',
			},
			filters: {
				q: '',
				length: null,
				person: false,
			},
			pag: {
				page: 1,
				perPage: 100,
				total: 0,
			},
			order: {
				order: 'desc',
				orderBy: this.$route.query.order_by || 'created_at',
			},
			keywords: [],
		}
	},
	computed: {
		...mapState(['people']),
	},
	created() {
		this.loadSales()
	},
	methods: {
		md5,

		loadSales() {
			this.states.keywords = 'loading'

			const params = {
				...this.filters,
				page: this.pag.page,
				perPage: this.pag.perPage,
				...this.order,
			}

			domainsApi.get('/keywords', { params }).then(({ data, headers }) => {
				this.pag.total = parseInt(headers['x-total'], 10)
				this.keywords.push(...data)
				this.$store.dispatch('loadPeople', data.map(l => l.person_id))

				this.states.keywords = 'loaded'
			}, Vue.toasted.error)

		},
		orderBy(field, defaultOrder) {
			this.order.order = this.order.orderBy == field ? (this.order.order === 'asc' ? 'desc' : 'asc') : defaultOrder
			this.order.orderBy = field
			this.keywords = []

			this.loadSales()
		},

		highlightQuery(text) {

			if (this.filters.q) {
				text = text.replace(this.filters.q, `<u class="fw-bolder">${this.filters.q}</u>`)
			}

			return text
		},

	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.keywords = []
				this.loadSales()
			}
		}
	},
}
</script>
